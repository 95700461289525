import React, { useContext, useEffect } from "react";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// SEO
import { PageSeo } from "../components/global/page-seo";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { PageColor } from "../components/context/page-color";
import { HeaderColor } from "../components/context/header-color";

const Page = styled.div`
  padding: 51px 0 0 0;
  min-height: calc(${(props) => props.height}px - 50px - 50px);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  color: #0b646f;

  & .page-container {
    max-width: 440px;
    margin: 0 auto;
  }

  & h1,
  & p {
    font-size: 22px;
    line-height: 32px;
    text-align: center;

    & a {
      color: #debb9b;
    }
  }

  @media (max-width: 960px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    & h1,
    & p {
      font-size: 12px;
      line-height: 17px;
    }
  }
`;

const NotFoundPage = () => {
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const height = use100vh();

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  return (
    <>
      <PageSeo
        title={
          currentLanguage === `en`
            ? `404 Page not found`
            : `404 Page non trouvé`
        }
        image={null}
        description={null}
        url={null}
      />

      <Page height={height}>
        <div className="page-container">
          <h1>
            {currentLanguage === `en`
              ? `404 Page not found`
              : `404 Page non trouvé`}
          </h1>
        </div>
      </Page>
    </>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
